<template>
  <transition name="fadedialog">
    <div v-if="isOverlayShow" class="vz-overlay" :class="{ permanent }" @click="close" />
  </transition>
</template>

<script setup lang="ts">
import { useLayoutStore } from "~/stores/personal/layout"
import { storeToRefs } from "pinia"
import { emitter } from "~/common/event-bus"

const props = defineProps({
  permanent: {
    type: Boolean,
    default: false
  }
})

const store = useLayoutStore()
const { isOverlayShow } = storeToRefs(store)

function close() {
  if (!props.permanent) {
    emitter.emit('hide-all-dialog')
  }
}

</script>